// SplashScreen.js
import React, { useEffect } from 'react';
import LottieAnimation from './LottieAnimation'; // Assume this is your Lottie component

const SplashScreen = ({ onLoaded }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onLoaded();
    }, 2200); // Show splash screen for 2 seconds

    return () => clearTimeout(timer);
  }, [onLoaded]);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
        <img src={`${process.env.PUBLIC_URL}/ALS_logo.png`} alt="Logo" />
      <LottieAnimation />
    </div>
  );
};

export default SplashScreen;
