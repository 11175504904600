import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import { defaultOptions } from './lottieConfig';

const LottieAnimation = ({ options = defaultOptions, height, width }) => {
  const [size, setSize] = useState({
    height: height || (window.innerWidth < 768 ? 300 : 450),
    width: width || (window.innerWidth < 768 ? 300 : 450),
  });

  useEffect(() => {
    const handleResize = () => {
      setSize({
        height: height || (window.innerWidth < 768 ? 300 : 450),
        width: width || (window.innerWidth < 768 ? 300 : 450),
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [height, width]);

  return <Lottie options={options} height={size.height} width={size.width} />;
};

export default LottieAnimation;
