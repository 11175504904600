import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { FaBars, FaTimes, FaMoon, FaSun, FaTrophy, FaListOl, FaCalendarAlt, FaUsers, FaNewspaper, FaInfoCircle, FaBook } from 'react-icons/fa';
import { fetchCurrentSeasonId } from '../services/api'; 

const Navbar = () => {
  const { seasonId: urlSeasonId } = useParams();
  const [seasonId, setSeasonId] = useState(urlSeasonId || null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(() => localStorage.getItem('theme') === 'dark');

  useEffect(() => {
    if (!urlSeasonId) {
      const fetchSeasonId = async () => {
        try {
          const response = await fetchCurrentSeasonId();
          setSeasonId(response.id);
        } catch (error) {
          console.error('Failed to fetch season ID:', error);
        }
      };
      fetchSeasonId();
    }
  }, [urlSeasonId]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <>
      <nav className="bg-gray-200 dark:bg-gray-900 p-2 shadow-lg sticky top-0 z-[1000] text-sky-900 dark:text-sky-300 font-oxanium">
        <div className="max-w-screen-xl mx-auto px-2">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <NavLink to="/home" className="flex items-center">
              <img src={`${process.env.PUBLIC_URL}/ALS_logo.png`} alt="Logo" className="h-16 mr-3" />
            </NavLink>

            {/* Main Navigation */}
            <ul className="hidden md:flex space-x-6 items-center text-gray-800 dark:text-sky-200 tracking-widest">
              <li>
                <NavLink
                  to="/home"
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    LIGA
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/tabela/obecna`}
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    TABELA
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/terminarz/wszystkie`}
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    TERMINARZ
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/druzyny"
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    DRUŻYNY
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/aktualnosci"
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    AKTUALNOŚCI
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/o-nas"
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    O NAS
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/regulamin"
                  className={({ isActive }) => 
                    `nav-link relative group ${isActive ? 'text-yellow-600 dark:text-yellow-500' : ''}`
                  }
                >
                  <span className="pb-1.5 relative inline-block hover:text-yellow-600 dark:hover:text-yellow-500">
                    REGULAMIN
                    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-yellow-500 w-0 group-hover:w-full ${isActive ? 'w-full' : ''} transition-all duration-300"></span>
                  </span>
                </NavLink>
              </li>
              <li>
                <div className="flex justify-center">
                  <button onClick={toggleDarkMode} className="mt-4 text-xs focus:outline-none flex items-center gap-2">
                    motyw: {darkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
                  </button>
                </div>
              </li>
            </ul>

            {/* Mobile Menu Button */}
            <div className="md:hidden">
              <button className="text-gray-900 dark:text-gray-100 focus:outline-none" onClick={toggleMobileMenu}>
                {isMobileMenuOpen ? <FaTimes size={30} /> : <FaBars size={30} />}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div className="fixed inset-0 bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 z-50 flex flex-col items-center justify-center min-h-screen">
          <button className="absolute top-6 right-6 text-gray-900 dark:text-gray-100 focus:outline-none" onClick={toggleMobileMenu}>
            <FaTimes size={30} />
          </button>
          <div className="flex flex-col items-center space-y-8 text-xl">
            <NavLink to="/home" className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest " onClick={toggleMobileMenu}>
            <FaTrophy className="mr-4 text-yellow-500" /> LIGA
            </NavLink>
            <NavLink to={`/tabela/obecna`} className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaListOl className="mr-4 text-yellow-500" />  TABELA
            </NavLink>
            <NavLink to={`/terminarz/wszystkie`} className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaCalendarAlt className="mr-4 text-yellow-500" />  TERMINARZ
            </NavLink>
            <NavLink to="/druzyny" className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaUsers className="mr-4 text-yellow-500" />  DRUŻYNY
            </NavLink>
            <NavLink to="/aktualnosci" className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaNewspaper className="mr-4 text-yellow-500" />  AKTUALNOŚCI
            </NavLink>
            <NavLink to="/o-nas" className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaInfoCircle className="mr-4 text-yellow-500" />  O NAS
            </NavLink>
            <NavLink to="/regulamin" className="flex item-center nav-link text-gray-900 dark:text-gray-100 tracking-widest" onClick={toggleMobileMenu}>
            <FaBook className="mr-4 text-yellow-500" /> REGULAMIN
            </NavLink>
            <div className="flex justify-center">
              <button onClick={toggleDarkMode} className="mt-2 text-sm focus:outline-none flex items-center gap-2">
                motyw: {darkMode ? <FaSun size={20} className="text-yellow-500" /> : <FaMoon size={20} />}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
