import React, { useState, useEffect } from 'react';
import { FaVolleyballBall } from 'react-icons/fa';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-gray-100 p-4 shadow-lg z-50">
      <div className="max-w-screen-lg mx-auto flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0">
        <div className="flex items-center">
          <FaVolleyballBall className="text-yellow-500 text-6xl md:text-3xl mr-4" />
          <p className="text-center sm:text-left mb-2 sm:mb-0">
            Używamy plików cookie, aby zapewnić najlepsze wrażenia z użytkowania naszej strony. Kontynuując przeglądanie, wyrażasz zgodę na ich wykorzystanie.
            <a href="/politykaPrywatnosci" className="text-yellow-500 underline ml-2">
              Dowiedz się więcej
            </a>.
          </p>
        </div>
        <button
          onClick={handleAccept}
          className="bg-yellow-500 text-gray-900 font-semibold px-4 py-2 rounded-lg hover:bg-yellow-600 transition-colors"
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
