import axios from 'axios';
// Define the environment mode
const ENVIRONMENT = process.env.NODE_ENV || 'production';

// Define your API and Image URLs based on the environment
const isProduction = ENVIRONMENT === 'production';

const CLOUD_URL = 'https://services.amatorskasiatka.pl/api/public'; // URL for cloud hosting production
const LOCAL_URL = 'http://localhost:8000/api/public'; // URL for local development 

const CLOUD_IMG_URL = 'https://services.amatorskasiatka.pl'; // Base URL for images in cloud production
const LOCAL_IMG_URL = 'http://localhost:8000'; // Base URL for images in local

// Set the correct URLs based on the environment
export const API_BASE_URL = isProduction ? CLOUD_URL : LOCAL_URL;
export const IMG_BASE_URL = isProduction ? CLOUD_IMG_URL : LOCAL_IMG_URL;


// Create an instance of axios with base settings
const api = axios.create({
  baseURL: API_BASE_URL, // Set the base URL for API requests
  headers: {
    'Content-Type': 'application/json',
  },
});

export const fetchAndUpdateStandings = async (seasonId) => {
  try {
    const response = await api.get(`/update-standings/${seasonId}/`);
    return response.data;
  } catch (error) {
    console.error(`Error updating and fetching standings for season ${seasonId}:`, error);
    throw error;
  }
};

export const fetchCurrentSeasonData = async () => {
  try {
    const response = await api.get('/seasons/');
    const seasons = response.data;

    // Find the season with the highest ID (the most recent season)
    const currentSeason = seasons.reduce((maxSeason, season) => 
      season.id > maxSeason.id ? season : maxSeason, seasons[0]
    );
    
    return currentSeason ? currentSeason : null;
  } catch (error) {
    console.error("Error fetching current season data:", error);
    return null;
  }
};

export const fetchCurrentSeasonId = async () => {
  try {
    const response = await api.get('/seasons/');
    const seasons = response.data;
   // console.log(seasons);

    // Find the season with the highest ID (the most recent season)
    const currentSeason = seasons.reduce((maxSeason, season) => 
      season.id > maxSeason.id ? season : maxSeason, seasons[0]
    );
    
    return currentSeason ? currentSeason.id : null;
  } catch (error) {
    console.error("Error fetching current season ID:", error);
    return null;
  }
};

export const fetchSchedule = async (seasonId) => {
  try {
    const response = await api.get(`/seasons/${seasonId}/schedule/`); // Use api instance here
    return response.data;
  } catch (error) {
    console.error('Error fetching schedule:', error);
    throw error;
  }
}

export const fetchCurrentMatches = async () => {
  try {
    const response = await api.get('/current-matches/');
    return response.data;  // Will be a list of current and next matches
  } catch (error) {
    console.error("Error fetching current matches", error);
    throw error;
  }
};


// API call to get all posts
export const fetchPosts = () => {
  return api.get('/posts/');
};

// API call to get a specific post by ID
export const fetchPostById = (postId) => {
  return api.get(`/posts/${postId}/`);
};

// API call to get all teams
export const fetchTeams = () => {
  return api.get('/teams/');
};

// API call to get a specific team by ID
export const fetchTeamById = (teamId) => {
  return api.get(`/teams/${teamId}/`);
};

// API call to get standings for a specific season
export const fetchStandings = (seasonId) => {
  return api.get(`/seasons/${seasonId}/standings/`);
};

// API call to get all seasons
export const fetchSeasons = () => {
  return api.get('/seasons/');
};

// API call to get a specific season by ID
export const fetchSeasonById = (seasonId) => {
  return api.get(`/seasons/${seasonId}/`);
};
