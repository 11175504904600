import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-4 px-2">
      <div className="container mx-auto flex justify-between items-center">
        
        {/* Left side - Privacy Policy link */}
        <span className="text-xs ">
          <Link to="/politykaPrywatnosci" className="hover:underline">
            Polityka prywatności
          </Link>
        </span>
        
        {/* Center - Copyrigth and year */}
        <span className="text-xs md:text-sm">
          Copyright &#169; ALS {new Date().getFullYear()}
        </span>
        
        {/* Right side - Developer link */}
        <span className="text-xs">
         
          <a href="https://www.anvicit.com" target="_blank" rel="noopener noreferrer" className="hover:underline">
          Dev by{' '} AnVic IT
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
