import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/widgets/Navbar';
import Footer from './components/widgets/Footer';
import CookieBanner from './components/widgets/CookieBanner';
import ScrollToTopButton from './components/utils/ScrollToTopButton';
import SplashScreen from './components/widgets/SplashScreen'; // Import SplashScreen
import { lazy } from 'react';

// Lazy loading route components
const MainPage = lazy(() => import('./components/pages/MainPage'));
const Teams = lazy(() => import('./components/pages/Teams'));
const Standings = lazy(() => import('./components/pages/Standings'));
const NewsPage = lazy(() => import('./components/pages/NewsPage'));
const Terminarz = lazy(() => import('./components/pages/Terminarz'));
const Rulebook = lazy(() => import('./components/pages/Rulebook'));
const PolitykaPrywatnosci = lazy(() => import('./components/pages/politykaPrywatnosci'));
const AboutUs = lazy(() => import('./components/pages/AboutUs'));
const NotFound = lazy(() => import('./components/utils/NotFound'));

function App() {
  const [isSplashScreenVisible, setSplashScreenVisible] = useState(true);

  useEffect(() => {
    const splashScreenShown = localStorage.getItem('splashScreenShown');

    if (splashScreenShown) {
      setSplashScreenVisible(false);
    }
  }, []);

  const handleSplashScreenLoaded = () => {
    setSplashScreenVisible(false);
    localStorage.setItem('splashScreenShown', 'true');
  };

  return (
    <Router>
      {isSplashScreenVisible ? (
        <SplashScreen onLoaded={handleSplashScreenLoaded} />
      ) : (
        <>
          <Navbar />
          <div className="min-h-screen bg-gray-100 dark:bg-gray-950 py-4 md:py-6 shadow-lg border-1 border-yellow-500 scroll-smooth">
            <Suspense fallback={<div className="flex justify-center items-center min-h-screen">Loading...</div>}>
            <Routes>
                <Route exact path="/home" element={<MainPage />} />
                <Route exact path="/" element={<MainPage />} />
                <Route path="/druzyny" element={<Teams />} />
                <Route path="/terminarz/:seasonSlug" element={<Terminarz />} />
                <Route path="/tabela/:seasonSlug" element={<Standings />} />
                <Route path="/aktualnosci" element={<NewsPage />} />
                <Route path="/regulamin" element={<Rulebook />} />
                <Route path="/polityka-prywatnosci" element={<PolitykaPrywatnosci />} />
                <Route path="/o-nas" element={<AboutUs />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </div>
          <Footer />
          <CookieBanner />
          <ScrollToTopButton />
        </>
      )}
    </Router>
  );
}

export default App;
